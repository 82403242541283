

import "./css/Loading.css"

 function Example() {

  return (
    <div class="mainloading">
<span class="loader"></span>   
  </div>

  )
}
export default Example;