

import React from 'react';
import Navbar from "./Components/Navbar"

import Service from "./Components/Services"
import Footer from "./Components/Footer"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
   
   <Navbar/>

   <p className="mt-20 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Our Services
          </p>
<Service className="m-20"/>



<Footer className="m-20"/>
    </div>
    

  );
}

export default App;
