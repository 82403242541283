
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Image1 from "../Images/wo1.jpg"
import Image2 from "../Images/wo2.jpg"
import Image3 from "../Images/wo3.png"
import "./css/Carousels.css"

const navigation = [
  { name: 'Home', href: '#' },
  { name: 'Service', href: '#' },
  { name: 'Contacts', href: '#' },
  { name: 'Company', href: '#' },
]

 function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div id="myCarousel" class="carousel slide mt-5" data-bs-ride="carousel" >
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active ">
      <div class='myContainer1'></div>
        <div class="container">
       

          <div class="carousel-caption text-center">
          <h1 class="animate__animated animate__slideInRight " id='pc1'>INDUSTRIAL PLANT EQUIPMENT & SPARE PARTS</h1><br/>
    <p class="animate__animated animate__fadeInBottomRight  " id='tc1'>Cutting-edge solutions to enhance industrial efficiency</p>
   
          </div>
        </div>
      </div>
      <div class="carousel-item ">
      <div class='myContainer2'></div>

              <div class="container">
          <div class="carousel-caption  text-center">
          <h1 class="animate__animated animate__slideInRight   " id='pc1'>HEAVY EQUIPMENT & MACHINERY SPARE PARTS</h1><br/>
    <p class="animate__animated animate__fadeInBottomRight  " id='tc1'>Reliable components for optimal equipment performance</p>
          </div>
        </div>
      </div>
      <div class="carousel-item ">
      <div class='myContainer3'></div>

              <div class="container">
          <div class="carousel-caption text-center">
          <h1 class="animate__animated animate__slideInRight " id='pc1'>HANDLING, LOADING & LIFTING EQUIPMENT</h1><br/>
    <p class="animate__animated animate__fadeInBottomRight " id='tc1'>Precision tools for construction, logistics, and manufacturing.</p>
          </div>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden"></span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden"></span>
    </button>

    
  </div>
  )
}
export default Example;