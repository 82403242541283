/* This example requires Tailwind CSS v2.0+ */
import "./css/Services.css"
import React, { useEffect, useState } from 'react';

const people = [
  {
    name: 'Jane Cooper',
    title: 'Paradigm Representative',
    role: 'Admin',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  }
,
  {
    name: 'Jane Cooper',
    title: 'Paradigm Representative',
    role: 'Admin',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  }
]

export default function Example() {
  const [isVisible, setIsVisible] = useState(false);


  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", reveal);
  useEffect(() => {
    reveal();
  }, [isVisible]);

  
  return (
    
    <div className="lists">

      <p  className=" text-lg leading-8 text-gray-600 text-justify p-4 mx-auto max-w-2xl lg:text-center ">Discover the possibilities with Noor Alafrah Trading L.L.C. Each trading object is a testament to our commitment to quality, innovation, and customer satisfaction. Explore the right solutions for your business, and elevate your operations with us.</p>
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 p-10 reveal" >
     

        <li
          
          className="col-span-1 flex flex-col text-center bg-white rounded-lg  divide-y divide-gray-200 "
        >
          <div className="flex-1 flex flex-col ">
          <div class="box-wrapper">
      
      <figure class="shape-box shape-box_half">
          <img src="https://www.kromtes.com/wp-content/uploads/2019/12/paslanmaz-tesisat-1024x683.jpg"/>
          <div class="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
          <figcaption>
              <div class="show-cont">
                  <h3 class="card-no">01</h3>
                  <p class="txt-sm font-semibold bg-white rounded-lg">Industrial Plant Equipment & Spare Parts Trading</p>
              </div>
              <ul class="list-disc text-sm">
  <li>Quality Solutions: Explore top-tier industrial plant equipment and spare parts designed for optimum performance.</li>
  <li>Trusted Brands: Representing industry-leading brands to ensure reliability and durability</li>
  <li>Certified Excellence: Backed by industry certifications, guaranteeing the highest standards.</li>
 
</ul>
             
          </figcaption>
          <span class="after"></span>
      </figure>

     
  </div>


          </div>
          
       
        </li>

        <li
          
          className="col-span-1 flex flex-col text-center bg-white rounded-lg  divide-y divide-gray-200"
        >
       


         
          
          <div className="flex-1 flex flex-col ">
          <div class="box-wrapper">
      
          <figure class="shape-box shape-box_half">
          <img src="https://sc04.alicdn.com/kf/H986dcbad1be8441abd965bcf344c7497O/241889/H986dcbad1be8441abd965bcf344c7497O.jpg"/>
          <div class="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
          <figcaption>
              <div class="show-cont">
                  <h3 class="card-no">02</h3>
                  <p class="txt-sm font-semibold bg-white rounded-lg">Heavy Equipment & Machinery Spare Parts Trading</p>
              </div>
              <ul class="list-disc text-sm">
  <li>Comprehensive Offerings: Discover a wide array of heavy equipment and spare parts for seamless operations.</li>
  <li>Notable Partnerships: Partnered with renowned brands to deliver excellence in heavy machinery solutions.</li>
 
</ul>
             
          </figcaption>
          <span class="after"></span>
      </figure>

     
  </div>


          </div>
         
        </li>
  
        <li    
          className="col-span-1 flex flex-col text-center bg-white rounded-lg  divide-y divide-gray-200" >
          <div className="flex-1 flex flex-col ">
          <div class="box-wrapper">   
          <figure class="shape-box shape-box_half">
          <img src="https://siplahtelkom.com/public/products/37710/2481358/jasa-foto-copy.1621838850.jpg"/>
          <div class="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
          <figcaption>
              <div class="show-cont">
                  <h3 class="card-no">03</h3>
                  <p class="txt-sm font-semibold bg-white rounded-lg">Type Writers, Photocopiers & Spare Parts Trading</p>
              </div>
              <ul class="list-disc text-sm ">
  <li>Office Automation Solutions: Providing technology-driven products for modern office automation</li>
  <li>Innovation at Work: Highlighting our commitment to innovation in office automation solutions.</li>
</ul>         
          </figcaption>
          <span class="after"></span>
      </figure>
  </div>
          </div>      
        </li>



        <li    
          className="col-span-1 flex flex-col text-center bg-white rounded-lg  divide-y divide-gray-200" >
          <div className="flex-1 flex flex-col ">
          <div class="box-wrapper">   
          <figure class="shape-box shape-box_half">
          <img src="https://259506.selcdn.ru/sites-static/site534308/9c6c6893-2e10-4e70-824e-7d6fe86fc402/9c6c6893-2e10-4e70-824e-7d6fe86fc402-4914498.jpeg"/>
          <div class="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
          <figcaption>
              <div class="show-cont">
                  <h3 class="card-no">04</h3>
                  <p class="txt-sm font-semibold bg-white rounded-lg">Handling, Loading & Lifting Equipment Trading</p>
              </div>
              <ul class="list-disc text-sm">
  <li>Precision Products: Explore a range of handling, loading, and lifting equipment designed for precision.</li>
  <li>Versatile Applications: Serving diverse industries with solutions tailored for various applications.</li>
</ul>         
          </figcaption>
          <span class="after"></span>
      </figure>
  </div>
          </div>      
        </li>




        <li    
          className="col-span-1 flex flex-col text-center bg-white rounded-lg  divide-y divide-gray-200" >
          <div className="flex-1 flex flex-col ">
          <div class="box-wrapper">   
          <figure class="shape-box shape-box_half">
          <img src="https://miro.medium.com/v2/resize:fit:8064/1*SvJB9BAf5EPiCTkVWWC73A.jpeg"/>
          <div class="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
          <figcaption>
              <div class="show-cont">
                  <h3 class="card-no">05</h3>
                  <p class="txt-sm font-semibold bg-white rounded-lg">Vending Machines Trading</p>
              </div>
              <ul class="list-disc text-sm bg-white rounded-lg">
  <li>Innovative Choices: Choose from various vending machines, each designed to meet different needs.</li>
  <li>Customization Services: Tailor vending solutions to match specific requirements with our customization services.</li>
</ul>         
          </figcaption>
          <span class="after"></span>
      </figure>
  </div>
          </div>      
        </li>


        <li    
          className="col-span-1 flex flex-col text-center bg-white rounded-lg  divide-y divide-gray-200" >
          <div className="flex-1 flex flex-col ">
          <div class="box-wrapper">   
          <figure class="shape-box shape-box_half">
          <img src="https://goring-kerr.com/metaldetectors/wp-content/uploads/2017/03/Goring-Kerr-metal-detector-68.jpg"/>
          <div class="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
          <figcaption>
              <div class="show-cont">
                  <h3 class="card-no">06</h3>
                  <p class="txt-sm font-semibold bg-white rounded-lg">Bakeries & Flour Mills Equipment Trading</p>
              </div>
              <ul class="list-disc text-sm">
  <li>Specialized Solutions: Showcase of equipment catering specifically to bakeries and flour mills.</li>
  <li>Features for Success: Highlighting the features and benefits that enhance production efficiency.</li>
</ul>         
          </figcaption>
          <span class="after"></span>
      </figure>
  </div>
          </div>      
        </li>


        <li    
          className="col-span-1 flex flex-col text-center bg-white rounded-lg  divide-y divide-gray-200" >
          <div className="flex-1 flex flex-col ">
          <div class="box-wrapper">   
          <figure class="shape-box shape-box_half">
          <img src="https://pereezdekb.com/uploadedFiles/eshopimages/big/4.jpg"/>
          <div class="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
          <figcaption>
              <div class="show-cont">
                  <h3 class="card-no">07</h3>
                  <p class="txt-sm font-semibold bg-white rounded-lg">Packing & Packaging Equipment Tradingg</p>
              </div>
              <ul class="list-disc text-sm">
  <li>Efficient Packaging: Description of advanced packaging solutions designed for efficiency.</li>
  <li>Sustainability Focus: Emphasizing our commitment to sustainable packaging initiatives.</li>
</ul>         
          </figcaption>
          <span class="after"></span>
      </figure>
  </div>
          </div>      
        </li>


        <li    
          className="col-span-1 flex flex-col text-center bg-white rounded-lg  divide-y divide-gray-200" >
          <div className="flex-1 flex flex-col ">
          <div class="box-wrapper">   
          <figure class="shape-box shape-box_half">
          <img src="https://tearapart.com/wp-content/uploads/2018/12/price-list-banner.jpg"/>
          <div class="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
          <figcaption>
              <div class="show-cont">
                  <h3 class="card-no">08</h3>
                  <p class="txt-sm font-semibold bg-white rounded-lg">Pumps, Engines, Valves & Spare Parts Trading</p>
              </div>
              <ul class="list-disc text-sm bg-white rounded-lg">
  <li>Comprehensive Range: Explore our extensive products for pumps, engines, valves, and spare parts.</li>
  <li>Reliability Matters: Emphasis on the quality and reliability of our offerings.</li>
</ul>         
          </figcaption>
          <span class="after"></span>
      </figure>
  </div>
          </div>      
        </li>


        <li    
          className="col-span-1 flex flex-col text-center bg-white rounded-lg  divide-y divide-gray-200" >
          <div className="flex-1 flex flex-col ">
          <div class="box-wrapper">   
          <figure class="shape-box shape-box_half">
          <img src="https://avatars.mds.yandex.net/get-altay/1879929/2a0000016a63fc325176066dcbc5bf48ca3d/XXL"/>
          <div class="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
          <figcaption>
              <div class="show-cont">
                  <h3 class="card-no">09</h3>
                  <p class="txt-sm font-semibold bg-white rounded-lg">Meat & Fish Processing Equipment & Machinery Trading</p>
              </div>
              <ul class="list-disc text-sm bg-white rounded-lg">
  <li>Processing Excellence: Overview of specialized equipment ensuring compliance with industry standards.</li>
  <li>Quality Assurance: Ensuring the highest standards in meat and fish processing solutions.</li>
  <li>Explore a world of efficient and reliable trading solutions with Noor Alafrah Trading L.L.C. Our concise services overview delivers key information without overwhelming your visitors.</li>
</ul>         
          </figcaption>
          <span class="after"></span>
      </figure>
  </div>
          </div>      
        </li>
    </ul></div>
  )
}
