import React, { useEffect, useState } from 'react';

export default function Example() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const elementPosition = document.getElementById('animated-element-welcome').offsetTop;

      if (scrollPosition > elementPosition && !isVisible) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check initial scroll position

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);

    return (
      <section className="relative isolate overflow-hidden bg-white px-6  py-2 lg:py-8 sm:py-8 lg:px-8" class={`animated-element-welcome ${isVisible ? 'visible' : ''}`} id="animated-element-welcome">
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-indigo-0 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div className="mx-auto max-w-2xl lg:text-center">
          <h1 className="font-semibold text-gray-900 text-2xl">Welcome to Noor Alafrah Trading L.L.C.</h1>
          <figure className="mt-1">
            <blockquote className="mt-6 text-lg leading-8 text-gray-600 text-justify p-4  sm:p-2">
              <p>
              Welcome to Noor Alafrah Trading L.L.C., where excellence meets diversity. Discover a wide array of trading solutions ranging from industrial plant equipment to handling and lifting machinery. Explore our comprehensive range of products and services designed to meet the diverse needs of various industries. Your journey towards quality and reliability starts here.
              </p>
              <p>
              Discover the possibilities with Noor Alafrah Trading L.L.C. Each trading object is a testament to our commitment to quality, innovation, and customer satisfaction. Explore the right solutions for your business, and elevate your operations with us.
              </p>
            </blockquote>
          
          </figure>
        </div>
      </section>
    )
  }

  