import logo from './logo.svg';
import './App.css';
import React from 'react';
import Home from "./Components/Home"
import Service from "./Service"
import Contacts from "./Contacts"
import Aboutus from "./Aboutus"
import PageNotFound from "./PageNotFound"
import Loading from "./Components/Loading"

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from "react";

  function App() {

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 2000)
    }, [])
    if (loading) {
        return <Loading/>
    }
  return (

    <div className="App">
     

      <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Service" element={<Service />} />
        <Route path="/Aboutus" element={<Aboutus />} />
        <Route path="/Contacts" element={<Contacts />} />
        <Route path="*" element={<PageNotFound />} />


      </Routes>
    </Router>
    </div>
  );
}
export default App;
