

import React from 'react';
import Navbar from "./Components/Navbar"

import Contact from "./Components/Contact"
import Footer from "./Components/Footer"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
   
   <Navbar/>

<br/><br/><br/>

<Contact className="m-36"/>



<Footer className="m-20"/>
    </div>
    

  );
}

export default App;
