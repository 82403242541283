import { LightBulbIcon, UsersIcon, FingerPrintIcon, LockClosedIcon ,CheckBadgeIcon,UserGroupIcon,ArrowPathIcon,
BuildingOffice2Icon} from '@heroicons/react/24/outline'
import "./css/Aboutas.css"
import React, { useEffect, useState } from 'react';

const features = [
 
  {
    name: 'Quality',
    description:
      'We are dedicated to providing products of the highest quality, meeting and exceeding industry standards.',
    icon: CheckBadgeIcon,
  },
  {
    name: 'Customer Satisfaction',
    description:
      'Customer success is at the core of our business. We are committed to understanding and meeting our clients unique needs.',
    icon: UsersIcon,
  },
  {
    name: 'Integrity',
    description:
      'We conduct our business with the utmost integrity, honesty, and transparency, fostering trust with our clients and partners.',
    icon: LockClosedIcon,
  }, {
    name: 'Innovation',
    description:
      'Embracing innovation, we continually explore and adopt cutting-edge solutions to stay at the forefront of industry advancements.',
    icon: LightBulbIcon,
  }, {
    name: 'Diversity and Inclusion',
    description:
      'We value diversity in perspectives, ideas, and backgrounds, fostering an inclusive environment that drives creativity and success.',
    icon: UserGroupIcon,
  },{
    name: 'Environmental Responsibility',
    description:
      'Committed to sustainability, we strive to minimize our environmental impact by promoting eco-friendly practices.',
    icon: ArrowPathIcon,
  },
  ,{
    name: 'Partnerships',
    description:
      'Building strong and lasting partnerships is key to our success. We collaborate with clients and suppliers to create mutually beneficial relationships.',
    icon: BuildingOffice2Icon,
  },
]

const features2 = [
  { name: 'Competitive Edge', description: 'Industry Leadership Benefit from our cutting-edge solutions that place us at the forefront of the market , Innovative Approach We continually push boundaries, offering services that give your business a competitive advantage' },
  { name: 'Commitment to Quality and Customer Service', description: 'Excellence at Every Step Our unwavering commitment to quality ensures products and services that meet the highest standards , Customer-Centric Focus Experience personalized service and support tailored to your unique needs.  ' },
  { name: 'Industry Expertise', description: 'In-Depth Knowledge Rely on a team with deep industry knowledge, providing insights and solutions that matter ,Proven Track Record Benefit from our history of success, showcasing our expertise and reliability in the trading sector.  ' },
  { name: 'Comprehensive Product Range', description: 'Diverse Offerings Access a wide range of quality products through Noor Alafrah Trading, catering to various industry needs ,One-Stop Solution Streamline your procurement process by choosing from our comprehensive product portfolio, ensuring convenience and efficiency for your business.' },

]

export default function Example() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const elementPosition = document.getElementById('animated-element').offsetTop;

      if (scrollPosition > elementPosition && !isVisible) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check initial scroll position

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);

  return (
    <div className=" mt-10" class={`animated-element ${isVisible ? 'visible' : ''}`} id="animated-element">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-blure">About Us</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Our Mission 
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
          At Noor Alafrah Trading L.L.C., our mission is to be a premier provider of trading solutions, delivering excellence and innovation across diverse industries. We strive to empower businesses with reliable and top-tier products, ensuring their success and growth.
          </p>
        </div>

        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Our Values
          </p>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16 " id="itemsabout">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blure">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                <p className="text-lg ">  {feature.name}</p>
                </dt>
                <dd className="mt-2 text-lg leading-7 text-gray-600  text-justify">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <div className="bg-white">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Why Choose Noor Alafrah Trading L.L.C.?</h2>
          <p className="mt-4 text-gray-500">
          Choose Noor Alafrah Trading L.L.C. for a partnership built on innovation, quality, and unmatched industry expertise. Your success is our priority.
          </p>

          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            {features2.map((feature) => (
              <div key={feature.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{feature.name}</dt>
                <dd className="mt-2 text-sm text-gray-500 text-justify">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          <img
            src="https://www.cincoze.com/data/files/202101/Warehouse-Logistics.jpg"
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="rounded-lg bg-gray-100"
          />
          <img
            src="https://www.vendnm.com/wp-content/uploads/2014/09/woman-at-vending-machine.jpg"
            alt="Top down view of walnut card tray with embedded magnets and card groove."
            className="rounded-lg bg-gray-100"
          />
          <img
            src="https://static.tildacdn.com/tild3562-6561-4438-b961-366239346438/Foto_no_leasing.jpeg"
            alt="Side of walnut card tray with card groove and recessed card area."
            className="rounded-lg bg-gray-100"
          />
          <img
            src="https://maihoangphotocopy.com/wp-content/uploads/2020/04/Copier-Start-Karachi-Supplier-1-3.jpg"
            alt="Walnut card tray filled with cards and card angled in dedicated groove."
            className="rounded-lg bg-gray-100"
          />
        </div>
      </div>
    </div>
    </div>
  )
}
