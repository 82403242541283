

import React from 'react';
import Navbar from "./Components/Navbar"

import Aboutas from "./Components/Aboutas"
import Footer from "./Components/Footer"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
   
   <Navbar/>



<Aboutas className="m-20"/>



<Footer className="m-20"/>
    </div>
    

  );
}

export default App;
