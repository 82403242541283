

import React from 'react';
import Navbar from "./Navbar"
import Carousels from "./Carousels"
import HomeSection from "./HomeSection"
import AboutAs from "./Aboutas"
import Service from "./Services"
import Contact from "./Contact"
import Footer from "./Footer"
import "./css/home.css"

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ImageAbout  from '../Images/abouts.jpeg';
function App() {
  return (
    <div className="App">
   
   <Navbar/>
<div className="">
<Carousels />
</div>

<HomeSection className=""/>


<div id='imageabout'>

<img src={ImageAbout}   class="img-fluid  mt-5 bg-black" alt="..."></img>

</div>
<AboutAs className=" "/>

<p className="mt-20 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Our Services
          </p>

<Service className="m-20"/>



<Contact className="m-20"/>

<Footer className="m-20"/>
    </div>
    

  );
}

export default App;
